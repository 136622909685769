<template>
  <v-container class="pa-0 mt-1 d-flex align-start" :style="{ maxWidth: maxWidth + 'px' }">
    <v-card class="rounded-lg" width="100%" :height="$vuetify.breakpoint.height - 100 + 'px'" :loading="loading">
      <v-card-title class="primary white--text pa-1">
        <v-btn icon dark to="/">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <span class="ml-2">All Sessions</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pa-0 rounded-lg card-relative" :style="{
        height: $vuetify.breakpoint.height - 120 + 'px',
        overflowY: 'auto',
      }">
        <v-list class="pa-0 rounded-lg" v-if="session_data.length">
          <template v-for="session in session_data">
            <v-list-item :key="session.id" class="pr-0">
              <v-list-item-action style="min-width: 60%">
                <div class="ml-2 d-flex align-center">
                  <v-btn v-if="isUpcoming(session) || isInBetween(session)" class="text-capitalize" depressed small
                    width="85" color="primary" :to="'/app/session/' + session.session_id">
                    Join
                  </v-btn>
                  <v-btn v-if="isCompleted(session) && !session.pre_recorded_video" class="text-capitalize" depressed
                    small width="85" color="primary" disabled>
                    Completed
                  </v-btn>
                  <v-btn v-if="isCompleted(session) && session.pre_recorded_video"
                    @click="openVideo(session.pre_recorded_video); sessionId = session.session_id"
                    class="text-capitalize" depressed small width="85" color="orange" dark>
                    View
                  </v-btn>
                  <p class="ml-4 mb-0">
                    {{ session.title }}
                  </p>
                </div>
              </v-list-item-action>
              <v-divider vertical></v-divider>
              <v-list-item-content class="pa-2">
                <div class="d-flex align-center justify-space-between">
                  <div>
                    <span v-if="session.display_time" class="">{{
                      getTimeSlot(session)
                    }}</span>
                  </div>
                  <div class="d-flex align-center pt-1 justify-xl-space-between">
                    <v-tooltip bottom v-if="session.slide_file">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="downloadSessionSlide(session.session_id)" v-bind="attrs" v-on="on"
                          :loading="loadingUrlFor[session.session_id]">
                          <v-icon>mdi-file-download-outline </v-icon>
                        </v-btn>
                      </template>
                      <span>Download Slides</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon @click="
                          downloadSessionIcsFile(
                            session.session_id,
                            session.title
                          )
                          " v-bind="attrs" v-on="on" :loading="loadingIcsDataFor[session.session_id]"
                          :disabled="isCompleted(session)">
                          <v-icon>mdi-calendar-clock</v-icon>
                        </v-btn>
                      </template>
                      <span>Add to Calendar</span>
                    </v-tooltip>
                    <v-tooltip bottom max-width="300">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                      </template>
                      <div class="session-description">
                        {{ session.description }}
                      </div>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <span v-on="on" class="action">
                          <v-btn icon @click="addToteBag(session.session_id)">
                            <v-icon>mdi-briefcase-plus-outline</v-icon>
                          </v-btn>
                        </span>
                      </template>
                      <span>Add to My Tote Bag</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="session.id"></v-divider>
          </template>
        </v-list>
        <v-list class="pa-0 rounded-lg" v-else>
          <v-list-item>
            <v-list-item-content>
              No sessions on {{ moment(this.time).format("ddd, MMM Do") }}
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-dialog v-model="show_preview" max-width="50%" persistent>
      <v-card>
        <v-card-text class="pt-2 rounded-lg px-0 py-0">
          <video ref="videoPlayer" @play="onPlay" @pause="onPause" @loadedmetadata="onLoadedMetadata"
            :src="preview_data" width="100%" v-if="preview_data" autoplay controls controlsList="nodownload"></video>
        </v-card-text>
        <v-btn style="position: absolute; top: 0px; right: 10px" color="red" dark fab small @click="closeDialog
          ">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from "@/helper/axios";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "SessionList",
  components: {},
  data() {
    return {
      time: this.moment().format("YYYY-MM-DD"),
      menu: false,
      session_data: [],
      loading: false,
      preview_data: null,
      show_preview: false,
      currentTime: new Date(),
      loadingIcsDataFor: {},
      assetsBaseURL: process.env.VUE_APP_ASSETS_BASE_URL,
      loadingUrlFor: {},
      startTime: null,
      endTime: null,
      videoDuration: 0,
      videoFullyWatched: false,
      watchedRanges: [],
      sessionId: null,
      programmaticallyPaused: false
    };
  },
  computed: {
    ...mapState("utils", ["maxWidth", "pageBackgroundImage"]),
  },
  watch: {
    time: function () {
      this.getSessions();
    },
    show_preview: function (value) {
      if (!value) {
        this.preview_data = null;
      }
    },
  },
  methods: {
    ...mapMutations("utils", ["setAlert", "setBackground"]),
    ...mapActions("agenda", ["fetchAgenda"]),
    ...mapMutations("agenda", ["setAgendaData"]),
    onPlay() {
      console.log("onPlay");
      if (this.startTime === null) {
        this.startTime = this.$refs.videoPlayer.currentTime;
      }
    },

    formatTime(seconds) {
      if (!isNaN(seconds)) {
        let minutes = Math.floor(seconds / 60);
        let remainingSeconds = Math.floor(seconds % 60);
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""
          }${remainingSeconds}`;
      }
      return "0:00";
    },
    recordWatchedRange() {
      if (this.startTime !== null) {
        this.endTime = this.$refs.videoPlayer?.currentTime;

        if (this.endTime > this.startTime) {
          const differenceInSeconds = this.endTime - this.startTime;


          let formattedTime;
          if (differenceInSeconds < 60) {
            formattedTime = `${differenceInSeconds.toFixed(2)} sec`;
          } else if (differenceInSeconds < 3600) {
            const differenceInMinutes = differenceInSeconds / 60;
            formattedTime = `${differenceInMinutes.toFixed(2)} min`;

          } else {
            const hours = differenceInSeconds / 3600;
            formattedTime = `${hours.toFixed(2)} hr`;
          }

          this.watchedRanges.push(formattedTime);
        }

        this.startTime = null; // Reset start time
      }

      if (this.watchedRanges.length) {
        this.addSessionTime();
      }
    },

    addSessionTime() {
      let _self = this;
      this.loading = "secondary";
      axios
        .post(`sessionView`, { session_id: this.sessionId, minutes: this.watchedRanges })
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            this.watchedRanges = []
            this.startTime = null
            this.endTime = null
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    onPause() {
      if (!this.programmaticallyPaused) {
        // Handle pause event only if not programmatically paused
        this.recordWatchedRange();
      } else {
        this.programmaticallyPaused = false; // Reset flag after handling programmatic pause
      }

    },
    // onSeeked() {
    //   this.recordWatchedRange();
    //   this.startTime = this.$refs.videoPlayer.currentTime;
    //   this.addSessionTime()
    // },
    onLoadedMetadata() {
      this.videoDuration = this.$refs.videoPlayer.duration;
      if (this.$refs.videoPlayer.autoplay) {
        this.startTime = this.$refs.videoPlayer.currentTime;
      }
    },
    onEnded() {
      this.recordWatchedRange();
    },

    closeDialog() {
      this.programmaticallyPaused = true;
      this.show_preview = false;
      this.onEnded();
    },


    addAgenda(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios
        .post(`agenda/add`, { session_id: session_id })
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            this.fetchAgenda();
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },

    addToteBag(session_id) {
      let _self = this;
      this.loading = "secondary";
      axios
        .post(`tote_bag/add`, { content_id: session_id, content_type: 1 })
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              variant: "success",
              message: response_data.message,
              dismissCountDown: 5000,
            });
          } else {
            let errors = response_data.error;
            for (let key in errors) {
              _self.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    isCompleted(session) {
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return endTime.diff(currentTime, "seconds") < 0;
    },
    isInBetween(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return currentTime.isBetween(startTime, endTime);
    },
    isUpcoming(session) {
      let startTime = this.moment(session.start_time);
      let endTime = this.moment(session.end_time);
      let currentTime = this.moment();
      return (
        !currentTime.isBetween(startTime, endTime) && !this.isCompleted(session)
      );
    },
    getTimeSlot(session) {
      return (
        this.moment(session.start_time).format("MMM Do, h:mm a - ") +
        this.moment(session.end_time).format("h:mm a")
      );
    },
    getSessions() {
      this.loading = "secondary";
      axios
        .get("/session/list")
        .then((data) => {
          if (data.data.data) {
            this.session_data = data.data.data;
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    openVideo(url) {
      this.show_preview = true;
      this.preview_data = url;
    },
    downloadSessionIcsFile(sessionId, sessionTitle) {
      this.loadingIcsDataFor = {
        ...this.loadingIcsDataFor,
        [sessionId]: true,
      };

      axios
        .get(`session/${sessionId}/download_ics`)
        .then((response) => {
          let resData = response.data;

          if (resData.status) {
            const linkElem = document.createElement("a");
            linkElem.href = `data:text/calendar;charset=utf-8,${encodeURIComponent(
              resData.data.calendarData
            )}`;
            linkElem.download = `${sessionTitle.replace(/ /g, "_")}.ics`;
            document.body.appendChild(linkElem);
            linkElem.click();
            document.body.removeChild(linkElem);
          } else {
            let errors = resData.error;

            for (let key in errors) {
              this.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
        })
        .catch(() => {
          this.setAlert({
            show: true,
            variant: "danger",
            message: "Something went wrong",
            dismissCountDown: 5000,
          });
        })
        .finally(() => {
          this.loadingIcsDataFor = {
            ...this.loadingIcsDataFor,
            [sessionId]: false,
          };
        });
    },
    downloadSessionSlide(sessionId) {
      if (!sessionId) {
        console.log("ERROR: session id not found");
        return;
      }

      this.loadingUrlFor = {
        ...this.loadingUrlFor,
        [sessionId]: true,
      };

      axios
        .get(`session/${sessionId}/slides/get_download_link`)
        .then((response) => {
          let resData = response.data;

          if (resData.status) {
            const linkElem = document.createElement("a");
            linkElem.href = resData.data.downloadUrl;
            document.body.appendChild(linkElem);
            linkElem.click();
            document.body.removeChild(linkElem);
          } else {
            let errors = resData.error;

            for (let key in errors) {
              this.setAlert({
                show: true,
                variant: "danger",
                message: errors[key].message,
                dismissCountDown: 5000,
              });
            }
          }
        })
        .catch(() => {
          this.setAlert({
            show: true,
            variant: "danger",
            message: "Something went wrong",
            dismissCountDown: 5000,
          });
        })
        .finally(() => {
          this.loadingUrlFor = {
            ...this.loadingUrlFor,
            [sessionId]: false,
          };
        });
    },
  },
  mounted: function () {
    this.getSessions();
    this.setBackground({
      image:
        'url("' + process.env.VUE_APP_ASSETS + 'auditorium_background.png")',
    });
    setInterval(() => {
      this.currentTime = new Date();
    }, 1000);
  },
  destroyed() {
    this.setBackground({
      image: "",
    });
  },
};
</script>

<style scoped>
.action[aria-disabled="true"] {
  cursor: not-allowed;
}

.action[aria-disabled="false"] {
  cursor: pointer;
}

.session-description {
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
